<template>
  <div>
    <!-- <div>lineId = {{lineId}}</div>
    <div>$liff_ID_Point = {{$liff_ID_Point}}</div> -->
  </div>
</template>

<script>
export default {
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_SKLFamily })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.checkTerm(profile.userId);
              this.lineId = profile.userId;
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.checkTerm(profile.userId);
            this.lineId = profile.userId;
          });
        }
        this.$liff.getFriendship().then(data => {
          if (!data.friendFlag) {
            this.$liff.openWindow({
              url: `https://line.me/R/ti/p/${this.$liff_Channel_ID}`,
              external: false
            });
            this.$liff.closeWindow();
          }
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  created() {
    if (!this.lineId) {
      this.lineId = this.$route.params.LineId;
    }
    if (!this.lineId) {
      this.lineId = decodeURIComponent(window.location.search).split(
        "liff.state=/"
      )[1];
    }
    if (!this.lineId) this.lineId = this.$route.params.LineId;
  },

  data() {
    return {
      lineId: "",
      getOs: ""
    };
  },
  methods: {
    async getToken() {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      this.getOs = this.$liff.getOS();

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/loginfamilyclub/${this.lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (result.detail.url) {
              // window.open(result.detail.url, "_self");
              // window.location.href = result.detail.url;
              // alert(`getOs = ${JSON.stringify(this.getOs)}`);

              if (this.getOs == "android") {
                this.$liff.openWindow({
                  url: `${result.detail.url}`,
                  external: false
                });
                setTimeout(() => {
                  this.$liff.closeWindow();
                }, 1000);
              } else {
                window.location.href = result.detail.url;
              } 
            } else {
              window.location.href = `${this.$PATH_SKL}`; //https://skl.co.th/family/point
            }
          } else {
            window.location.href = `${this.$PATH_SKL}`; //https://skl.co.th/family/point
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    closeWindow() {
      console.log("closeWindow is worked leff");
      try {
        this.$liff.closeWindow();
      } catch (error) {
        console.log(error);
      }

      console.log("closeWindow is worked windows");
      try {
        window.close();
      } catch (error) {
        console.log(error);
      }
    },
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          // alert(`/customer/checkterms/ response ${JSON.stringify(result)}`)
          if (result.result == 1) {
            if (!result.detail) {
              this.$router.push({
                path: "/policy",
                query: {
                  lineid: lineId,
                  term: true
                }
              });
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
            } else {
              this.getToken();
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            this.$router.push({
              path: "/policy",
              query: {
                lineid: lineId,
                term: true
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style></style>
